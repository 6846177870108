import React from "react";
import "./SectionHeader.scss";

const SectionHeader = props => {
  const { title, subtitle, style, className, button } = props;
  return (
    <div style={style} className={`section-header ${className || ""}`}>
      <div className="line"/>
      <div>
        <h2>{title.toUpperCase()}</h2>
        {subtitle && <p>{subtitle}</p>}
      </div>
      <hr />
      {button && (
        <div onClick={button.onClick} className="button">
          {button.title}
        </div>
      )}
    </div>
  );
};

export default SectionHeader;

import React from "react";
import "./MobileMenu.scss";

export const MobileMenu = props => {
  const { items, scrollToItem, visible } = props;
  const ITEM_HEIGHT = 80;
  const _getNavBarItems = () => {
    return items.map((obj, index) => {
      return (
          <li onClick={() => scrollToItem(index)}>
              {obj.toUpperCase()}
            {index !== items.length -1 && <hr />}
          </li>
      );
    });
  };
  return (
    <div
      style={{ height: visible ? items.length * ITEM_HEIGHT  : 0 }}
      className="MobileMenu"
    >
      <ul>{_getNavBarItems()}</ul>
    </div>
  );
};

import React from "react";
import "./Banner.scss";
import {
  FaMapMarkerAlt,
  FaEnvelope,
  FaTwitter,
  FaResearchgate
} from "react-icons/fa";

export const Header = () => {
  return (
    <div className="header-container">
      <h3>DR</h3>
      <div className="name-container">
        <h1>Ossi Nokelainen</h1>
        <h3>, FLS</h3>
      </div>
    </div>
  );
};

export const InfoRow = props => {
  const { item, style } = props;
  return (
    <div style={style} onClick={item.onClick} className="info-row">
      <item.Icon className="icon" />
      {item.textComponent || item.text}
    </div>
  );
};

export const SomeIcons = (props) => {
  const {style} = props;
  return (
    <div style={style} className="some-container">
      <FaTwitter
        onClick={() => window.open("https://twitter.com/OssiNokelainen")}
        className="icon"
      />
      <i
        onClick={() =>
          window.open("https://scholar.google.com/citations?user=VuZ4ZcIAAAAJ")
        }
        className="ai ai-google-scholar-square icon"
      ></i>
      <FaResearchgate
        onClick={() =>
          window.open("https://www.researchgate.net/profile/Ossi_Nokelainen")
        }
        className="icon"
      />
    </div>
  );
};

const Banner = () => {
  const infoRows = [
    {
      Icon: FaMapMarkerAlt,
      text: `Department of Biological and Environmental Science, \nP.O. Box 35, 40014 University of Jyväskylä, FINLAND`,
      onClick: () =>
        window.open(
          "https://maps.google.com/?q=Department of Biological and Environmental Science, 40014 University of Jyväskylä, FINLAND"
        )
    },
    {
      Icon: FaEnvelope,
      onClick: () => window.open("mailto:ossi.nokelainen@jyu.fi"),
      text: `ossi.nokelainen@jyu.fi`
    }
  ];

  return (
    <div className="banner">
      <Header />
      <div className="info-rows">
        {infoRows.map(item => (
          <InfoRow item={item} />
        ))}
      </div>
      <SomeIcons />
    </div>
  );
};

export default Banner;

import React from "react";
import SectionHeader from "../SectionHeader";
import "./Collaborations.scss";

const CollaborationBlock = props => {
  const { item } = props;
  return (
    <div className="collaboration-block">
      <img src={item.image} alt="Collaboration" className="image" />
      <div className="text-container">
        <h3>{item.title}</h3>
        <p>{item.subtitle}</p>
        <p className="description">{item.description}</p>
      </div>
      <hr />
    </div>
  );
};

const Collaborations = props => {
  const { forwardRef } = props;
  const collaborations = [
    {
      title: "Maintenance of colour polymorphism in aposematic tiger moths",
      subtitle: "J. Mappes, University of Jyväskylä",
      description:
        "The wood tiger moth warns predators on its unprofitability by its conspicuous coloration. It comes in many varieties including white, yellow and red, often co-occurring within a population. As predators are expected to select for the locally most efficient warning signal, there is a perplexing range of polymorphism present in this species and as such, it presents an evolutionary puzzle.",
      image: require("../../assets/img/plantaginis.jpg")
    },
    {
      title: "Experimental approaches on bird vision, behaviour and decision making",
      subtitle: "Konnevesi Research Station",
      description:
        "Many birds, including blue tits, see ultraviolet wavelengths beyond the human visible spectrum. This has significant consequences on how birds make decisions for vision-based cues in the wild. To this end, it is important to ‘ask the birds’ on their preference of colours and follow their behaviour in controlled settings.",
      image: require("../../assets/img/bluetit.jpg")
    },
    {
      title: "Camouflage optimisation through development in green shore crabs",
      subtitle: "M. Stevens, University of Exeter",
      description:
        "Young green shore crabs show remarkable variation in coloration and it has been suggested that their variable appearance may help them to hide from predators in the habitats they use. However, as crabs grow, they become more mobile and adult crabs, in contrast, are known to possess a more uniform coloration. This creates a dilemma: how to remain hidden in habitats that are variable and very different in appearance.",
      image: require("../../assets/img/shorecrab.jpg")
    },
    {
      title: "Colour polymorphism of the Indo-Pacific coconut crabs",
      subtitle: "T. Caro, UCLA/University of Bristol",
      description:
        "The coconut crab is the world’s largest terrestrial invertebrate, can grow to four kilograms with a lifespan up to 60 years, and have claws powerful enough to break coconuts. Adult coconut crabs are variable in coloration including near black, dark purple or blue individuals and orange or red individuals but the type of variation has never been systematically characterized across its range to understand its function.",
      image: require("../../assets/img/coconutcrab.jpg")
    },
    {
      title: "Evolution of camouflage matching in Sahara-Sahel desert rodents",
      subtitle: "Z. Boratyński, University of Porto",
      description:
        "The Sahara-Sahelian rodent community represents one of the best documented cases of phenotype-environment matching comprising a marked taxonomic diversity. Spanning over a large biogeographic extent, these communities face extreme conditions and heterogeneous selective pressures, allowing clear ecological predictions for testing impacts of ongoing climatic and landscape changes.",
      image: require("../../assets/img/desertrodent.jpg")
    },
    {
      title: "Host-plant ecology of the Bicyclus safitza in Africa",
      subtitle: "P. Brakefield, University of Cambridge and B. Ripley, Rhodes University",
      description:
        "Eight million years ago in Africa, the radiation of C4 grasses under high-temperature and low ambient CO2 levels, transformed much of a forested landscape into savanna. This changed the host plant regime of herbivores, and the subsequent diversification of many consumer lineages suggests that the radiations of grasses and grazers may be evolutionary linked. To understand this, we examined mechanisms for this plant-herbivore interaction with the grass-feeding butterfly, common bush brown (B. safitza), in South Africa.",
      image: require("../../assets/img/bicyclussafitza.jpg")
    }
  ];
  return (
    <div ref={forwardRef} className="section">
      <SectionHeader className="project-section" subtitle={"Ongoing collaboration and completed projects"} title="Projects" />
      <div className="section-content">
        {collaborations.map(item => (
          <CollaborationBlock item={item} />
        ))}
      </div>
    </div>
  );
};

export default Collaborations;

import React from "react";
import "./App.scss";
import Banner from "./components/Banner";
import NavBar from "./components/NavBar";
import Research from "./components/Sections/Research";
import Collaborations from "./components/Sections/Collaborations";
import smoothscroll from "smoothscroll-polyfill";
import Publications from "./components/Sections/Publications";
import { MobileMenu } from "./components/MobileMenu";
import Contact from "./components/Sections/Contact";
smoothscroll.polyfill();

class App extends React.PureComponent {
  navItems = ["Research", "Projects", "Publications", "Contact"];
  research: any = React.createRef();
  collaborations: any = React.createRef();
  publications: any = React.createRef();
  contact: any = React.createRef();
  sections: any[] = [
    this.research,
    this.collaborations,
    this.publications,
    this.contact
  ];
  state = {
    scrollIndex: null,
    showTitle: false,
    menuVisible: false
  };

  _toggleMenu = () => {
    this.setState({
      menuVisible: !this.state.menuVisible
    });
  };

  _handleScroll = () => {
    const scroll = window.scrollY;
    let scrollIndex;
    this.sections.forEach((ref, i) => {
      if (scroll >= ref.current.offsetTop - 100) {
        scrollIndex = i;
      }
    });
    if (scroll + window.innerHeight >= document.documentElement.scrollHeight - 50) {
      scrollIndex = this.sections.length - 1;
    }
    const showTitle = scroll > 130;
    this.setState({ scrollIndex, showTitle });
  };

  _scrollToSection = index => {
    if (this.state.menuVisible) this._toggleMenu();
    if (index === -1) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      return;
    }
    const ref = this.sections[index];
    window.scrollTo({
      top: ref.current.offsetTop - 50,
      left: 0,
      behavior: "smooth"
    });
  };

  componentDidMount = () => {
    window.addEventListener("scroll", this._handleScroll);
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this._handleScroll);
  }

  render() {
    const { scrollIndex, showTitle } = this.state;
    return (
      <div className="App">
        <div className="bg" />
        <NavBar
          toggleMenu={this._toggleMenu}
          showTitle={showTitle}
          scrollTo={this._scrollToSection}
          scrollIndex={scrollIndex}
          items={this.navItems}
        />
        <Banner />
        <MobileMenu
          scrollToItem={this._scrollToSection}
          items={this.navItems}
          visible={this.state.menuVisible}
        />
        <Research forwardRef={this.research} />
        <Collaborations forwardRef={this.collaborations} />
        <Publications forwardRef={this.publications} />
        <Contact forwardRef={this.contact} />
      </div>
    );
  }
}

export default App;

import React from "react";
import SectionHeader from "../SectionHeader";
import "./Publications.scss";

const PublicationRow = (props) => {
  return <div className="publication-row">{props.item}</div>;
};

const Publications = (props) => {
  const { forwardRef } = props;
  const rows = [
    "Macedo T, Campos JC, Nokelainen O, Scott-Samuel NE, Boratynski Z. 2022 The effect of spatial and temporal scale on camouflage in North African rodents. Biological Journal of the Linnean Society. 137(3)523-533.",
    "Nokelainen O, Helle H, Hartikka J, Jolkkonen J, Valkonen J. 2022 The Eurasian Treecreeper Certhia familiaris has an effective camouflage against mammalian but not avian vision in boreal forests. IBIS 164(3)679-691.",
    "Nokelainen O, Galarza JAG, Suisto K, Kirvesoja J, Mappes J. 2022 Genetic colour variation visible for predators and conspecifics is concealed from humans in a polymorphic moth. Journal of Evolutionary Biology 35(3)567-478.",
    "Nokelainen O, Rezende F, Valkonen J, Mappes J. 2022 Context-dependent coloration of prey and predator decision making in contrasting light environments. Behavioural Ecology. 33(1)77-86.",
    "Nokelainen O, Scott-Samuel NE, Nie YG, Wei F, Caro T. 2021 The giant panda is cryptic. Scientific Reports. 11(1)1-10.",
    "Winters A, Lommi J, Kirvesoja J, Nokelainen O, Mappes J. 2021 Multimodal aposematic defenses through the predation sequence. Frontiers in Ecology and Evolution. 9:657740.",
    "Troscianko J, Nokelainen O, Skelhorn J, Stevens M. 2021 Variable crab camouflage patterns defeat search image formation. Communications Biology. 4 (1), 1-9.",
    "Silvasti S, Valkonen J, Nokelainen O. 2021 Behavioural thresholds of blue tit colour vision and the effect of background chromatic complexity. Vision Research. 182, 46-57.",
    "Rönkä K, Valkonen J, Nokelainen O, Rojas B, Gordon S, Burdfield-Steel E, Tasane T, Mappes J. 2020 Geographic mosaic of selection by avian predators on hindwing warning colour in a polymorphic aposematic moth. Ecology Letters. 23 (11) 1654-1663.",
    "Nokelainen O, Sreelatha L, Brito JC, Campos JC, Scott-Samuel NE, Valkonen J, Boratynski Z. 2020 Camouflage in arid environments: the case of Sahara-Sahel desert rodents. Journal of Vertebrate Biology. 69 (2) 1-12.",
    "Nokelainen O, Scott-Samuel NE, Valkonen J, Boratynski Z. 2020 Camouflage accuracy in Sahara-Sahel desert rodents. Journal of Animal Ecology. 89 (7) 1658-1669.",
    "Caro T, Hamad H, Suleiman Rashid R, Kloiber U, Pretelli I, Nokelainen O, Borgerhoff Mulder M, Morgan V. 2020 A case of coconut crabs on Zanzibar highlights global threats and conservation solutions. Oryx. Pp.1-8.",
    "Arias M, Mappes J, Desbois C, Gordon S, McClure M, Elias M, Nokelainen O, Gomez D. 2019 Transparency reduces predator detection in chemically protected clearwing butterflies. Functional Ecology. 33 (6) 1110-1119.",
    "Nokelainen O, Maynes R, Mynott S, Price T, Stevens M. 2019. Improved camouflage through ontogenetic colour change confers reduced detection risk in shore crabs. Functional Ecology. 33 (4) 654-669.",
    "Rojas B, Burdfield-Steel E, de Pasqual C, Gordon S, Hernández L, Mappes J, Nokelainen O, Rönkä K, Lindstedt C 2018. Multimodal aposematic signals and their emerging role in mate attraction. 6, 93. Frontiers in Ecology and Evolution.",
    "Nokelainen O, Stevens M, Caro T 2018 Colour polymorphism in the coconut crab (Birgus latro). Evolutionary ecology. 32 (1) 75-88.",
    "Nokelainen O, van Bergen E, Ripley B, Brakefield P 2018 Adaptation of a tropical butterfly into a temperate zone. Biological Journal of the Linnean Society. 123 (2) 279-289.",
    "Nokelainen O, Hubbard N, Lown AE, Wood LE, Stevens M 2017. Through predators’ eyes - phenotype-environment associations in shore crab coloration at different spatial scales. Biological Journal of the Linnean Society. 122 (4) 738-751.",
    "Nokelainen O, Rojas B, Valkonen J 2017. Camouflage. Encyclopedia of Evolutionary Psychological Science.",
    "Rojas B, Nokelainen O, Valkonen J 2017. Aposematism. Encyclopedia of Evolutionary Psychological Science.",
    "Nokelainen O, Stevens M 2016. Camouflage. Current Biology. 26(14)R654-656.",
    "Nokelainen O, Ripley B, van Bergen E, Osborne C, Brakefield P 2016. Preference for C4 shade grasses increases hatchling performance in the butterfly, Bicyclus safitza. Ecology and Evolution. ece32235.",
    "Gordon S, Kokko H, Rojas B, Nokelainen O, Mappes J 2015. Colour polymorphism, torn apart by opposing positive frequency-dependent selection, yet maintained in space. Journal of Animal Ecology. 84(6)1555-1564.",
    "Rojas B, Valkonen J, Nokelainen O 2015 Aposematism. Current Biology. 25(9)R350-351.",
    "Galarza J, Nokelainen O, Ashrafi R, Hegna R, Mappes J 2014. Temporal relationship between genetic and warning signal variation in the aposematic wood tiger moth. Molecular Ecology. 23(20)4939-4957.",
    "Valkonen J, Nokelainen O, Jokimäki M, Kuusinen E, Paloranta M, Peura M, Mappes J 2014. From deception to frankness: Benefits of ontogenetic shift in the anti-predator strategy of alder moth Acronicta alni larvae. Current Zoology. 60(1).",
    "Nokelainen O, Valkonen J, Lindstedt C, Mappes J 2014. Changes in predator community structure shifts the efficacy of two warning signals in Arctiid moths. Journal of Animal Ecology. 83(3)598-605.",
    "Suisto K, Nokelainen O 2013. The wood tiger moth (Parasemia plantaginis) in help of finding factors maintaining diversity. In Finnish. Baptria. 38(4)104-107.",
    "Nokelainen O 2013. Many forms of wood tiger moth (Parasemia plantaginis) – selective heterogeneity favours polymorphic warning signals. Diss. University of Jyväskylä.  ",
    "Nokelainen O, Lindstedt C, Mappes J 2013. Environment-mediated morph-linked immune and life-history responses in the aposematic wood tiger moth. Journal of Animal Ecology. 82(3)653-662.",
    "Hegna R, Nokelainen O, Hegna JR, Mappes J 2013. To quiver or to shiver: Increased melanisation benefits thermoregulation but reduces warning signal efficacy in the wood tiger moth. Proceedings of the Royal Society B – London. 280(1755):20122812. ",
    "Valkonen J, Nokelainen O, Niskanen M, Kilpimaa J, Björklund M, Mappes J 2012. Variation in predator species abundance can cause variable selection pressure on warning signaling prey. Ecology and evolution. 1971-1976. ",
    "Nokelainen O, Hegna R, Talsma Reudler J, Lindstedt C, Mappes J 2012. Trade-off between warning signal efficacy and mating success in the wood tiger moth. Proceedings of the Royal Society B – London. 279(1727):257-265.",
    "Valkonen J, Nokelainen O, Mappes J 2011. Antipredatory function of head shape in vipers. PloS One. e22272.",
  ];
  return (
    <div ref={forwardRef} className="section">
      <SectionHeader
        className="publications"
        title="Publications"
        button={{
          onClick: () =>
            window.open(
              "https://scholar.google.com/citations?user=VuZ4ZcIAAAAJ"
            ),
          title: "Open in Google Scholar",
        }}
      />
      <div className="section-content">
        {rows.map((row) => (
          <PublicationRow item={row} />
        ))}
      </div>
    </div>
  );
};

export default Publications;

import React from "react";
import SectionHeader from "../SectionHeader";
import "./Contact.scss";
import { Header, InfoRow, SomeIcons } from "../Banner";
import { FaEnvelope } from "react-icons/fa";

const Contact = props => {
  const { forwardRef } = props;
  return (
    <div ref={forwardRef} className="section contact">
      <SectionHeader title="Contact" />
      <div className="content">
        <img
          className="end-cover"
          alt="End cover"
          src={require("../../assets/img/end_cover.jpg")}
        />
        <div className="overlay">
          <div>
            <Header />
            <InfoRow
            style={{marginTop: 10}}
              item={{
                Icon: FaEnvelope,
                onClick: () => window.open("mailto:ossi.nokelainen@jyu.fi"),
                text: `ossi.nokelainen@jyu.fi`
              }}
            />
            <SomeIcons style={{marginTop: 15, marginBottom: 5}} />
            <div onClick={() => window.open("mailto:ossi.nokelainen@jyu.fi")} className="button">
              Contact me
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

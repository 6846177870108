import React, { useState } from "react";
import "./NavBar.scss";
import { FaBars } from "react-icons/fa";

const NavBarItem = props => {
  const { item, active, onClick, setHoverIndex, index } = props;
  return (
    <li onMouseEnter={() => setHoverIndex(index)} onMouseLeave={() => setHoverIndex(null)} onClick={onClick} className={`navbar-item ${active ? " active" : ""}`}>
      {item.toUpperCase()}
    </li>
  );
};

const NavBar = props => {
  const { items, scrollIndex, showTitle, scrollTo, toggleMenu} = props;
  const [hoverIndex, setHoverIndex] = useState(null);
  return (
    <div className="navbar">
      <h3 onClick={() => scrollTo(-1)} style={{opacity: showTitle ? 1 : 0}}>Ossi Nokelainen</h3>
      <ul>
        {items.map((item, i) => (
          <NavBarItem setHoverIndex={setHoverIndex} index={i} onClick={() => scrollTo(i)} active={hoverIndex != null ? hoverIndex === i : scrollIndex === i} item={item} />
        ))}
      </ul>
      <button onClick={toggleMenu} className="hamburger">
          <FaBars color="white" size={30} />
        </button>
    </div>
  );
};
export default NavBar;

import React from "react";
import SectionHeader from "../SectionHeader";

const Research = props => {
  const { forwardRef } = props;
  return (
    <div ref={forwardRef} className="section">
      <SectionHeader title="Research" />
      <div className="section-content">
        <div className="highlight">
          <p>
            Why do some animals advertise themselves with bold patterns or
            dazzling colours, while others do not?
          </p>
        </div>
        <p>
          This question has intrigued naturalists for centuries and remains at
          the core of biological studies today. At first glance, coloration may
          appear a superficial trait, but it is more than that. Coloration has
          many fitness effects influencing, for example, an animals’ ability to
          regulate body temperature, to fight against pathogens, to find a mate
          as well as to hide from or warn off predators. Because of its
          tractability to measure heritability, phenotypic variation and
          fitness, coloration enables us to study selection of adaptive traits.
          Colours can therefore help us to understand how biological diversity
          is maintained in the wild.
          {
            <React.Fragment>
              <br />
              <br />
            </React.Fragment>
          }
          My research mainly focuses on coloration, polymorphism and
          predator-prey interactions. I use multispectral imaging, vision
          modelling and experimental approaches at the interface of sensory,
          behavioural, and evolutionary ecology. I am interested in how
          perception shapes the function(s) of colours, and also, how different
          functions trade-off with each other and get optimised. Although birds
          are close to my heart, I work with various study organisms across the
          world including birds, crabs, mammals, butterflies, moths and many
          more.
        </p>
      </div>
    </div>
  );
};

export default Research;
